.home-page {
  .hero-section {
    h1 {
      font-size: 12.8rem;
      @media (max-width: 1200px) {
        font-size: 9rem;
      }
      @media (max-width: 900px) {
        font-size: 6rem;
      }
    }
  }
}
