.home-page {
  .toolstack-section {
    h2 {
      color: var(--color--primary);
      leading-trim: both;
      text-edge: cap;
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 120%; /* 28.8px */
      @media (max-width: 1200px) {
        font-size: 2rem;
      }
      @media (max-width: 900px) {
        font-size: 1.8rem;
      }
    }
    .d-flex {
      display: flex !important;
    }

    .toolstack-tiles {
      flex-wrap: wrap;
      .standardTile {
        flex-direction: row !important;
        row-gap: 10px;
        pointer-events: none;
        @media (max-width: 600px) {
          row-gap: 4px;
          padding: 6px;
        }
        img {
          width: 64px;
          aspect-ratio: 1;
          object-fit: contain;
          object-position: center center;
          @media (max-width: 1200px) {
            width: 48px;
          }
          @media (max-width: 900px) {
            width: 32px;
          }
          @media (max-width: 600px) {
            width: 24px;
          }
        }
        h3 {
          font-size: 2.4rem;
          font-weight: 400;
          @media (max-width: 1200px) {
            font-size: 2rem;
          }
          @media (max-width: 900px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}
