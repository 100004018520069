.badge-dropdown {
  border: 1px dashed var(--color--primary);
  color: var(--color--primary);
  padding: 0px 2.5rem;
  border-radius: 20px;
  display: inline-flex;
  line-height: 105% !important;
  padding-bottom: 6px;
  position: relative;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  // min-width: 340px;
  min-width: min-content;

  @media (max-width: 1200px) {
    border-radius: 8px;
    padding: 0px 1rem;
    padding-bottom: 4px;
  }

  .badge-dropdown-toggle {
    display: inline-flex;
    white-space: nowrap;
    cursor: pointer;
    width: 100%;
    .polygon {
      margin: auto 0px auto auto;
      transform: translateY(3px);
    }
  }

  .badge-dropdown-menu {
    position: absolute;
    top: 0%;
    // width: calc(100% + 2px);
    width: max-content;
    // min-width: min-content;
    left: -1px;
    border: 1px dashed var(--color--primary-0a);
    color: var(--color--primary);
    background: var(--color--primary-0a);
    padding: 0px 1.5rem 0px 1.5rem;
    border-radius: 20px;
    line-height: 105% !important;
    overflow: hidden;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition-duration: 1.3s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    @media (max-width: 1200px) {
      border-radius: 8px;
      padding: 0px 0.5rem;
      padding-bottom: 4px;
    }
    .badge-dropdown-menu-item {
      white-space: nowrap;
      padding: 0px 1rem 0px 1rem;
      @media (max-width: 1200px) {
        padding: 0px 0.5rem;
      }
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      .polygon {
        opacity: 0;
      }
    }
  }

  &.--isOpen {
    border-color: var(--color--primary-0a);
    z-index: 3;
    transition-duration: 0.3s;

    .badge-dropdown-toggle {
      pointer-events: none;
    }

    .badge-dropdown-menu {
      top: -15px;
      @media (max-width: 1200px) {
        top: -4px;
      }
      pointer-events: initial;
      border-color: var(--color--primary);
      border: 1px solid var(--color--primary);
      background: var(--color--primary);
      opacity: 1;
      height: auto;
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);

      // @keyframes bounceAnimation {
      //   0% {
      //     padding: 12px 1.5rem 32px 1.5rem;

      //   }
      //   100% {
      //     padding: 12px 1.5rem 12px 1.5rem;

      //   }
      // }
      // animation: bounceAnimation 0.3s linear;
      padding: 15px 1.5rem 15px 1.5rem;
      @media (max-width: 1200px) {
        padding: 4px 0.5rem;
      }
      .badge-dropdown-menu-item {
        padding: 0px 1rem 0px 1rem;
        color: var(--color--background);
        @media (max-width: 1200px) {
          padding: 0px 0.5rem 0px 0.5rem;
        }
        cursor: pointer;

        .polygon {
          opacity: 0;
        }

        $itemRadius: 12px;
        @media (max-width: 1200px) {
          $itemRadius: 4px;
        }
        &.first-child {
          border-radius: $itemRadius $itemRadius 0px 0px;
        }
        &.last-child {
          border-radius: 0px 0px $itemRadius $itemRadius;
        }

        &:hover {
          opacity: 0.9;
          background: var(--color--background);
          color: var(--color--primary);
          transition-duration: 0.3s;
        }
        &.active {
          opacity: 1;
          background: var(--color--background);
          color: var(--color--primary);
          transition-duration: 0.3s;
        }
      }
    }
  }
}

.theme-light {
  .badge-dropdown {
    .badge-dropdown-menu {
      .badge-dropdown-menu-item {
        color: black;
        &.active {
          color: white;
          background: black;
        }
        &:hover {
          color: white;
          background: black;
        }
      }
    }
  }
}
