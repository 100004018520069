.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  position: fixed;
  width: 100%;
  z-index: 999;
  .read-cv {
    font-size: 1.6rem;
    line-height: 1;
    padding: 12px 16px;
    font-weight: 500;
  }
}

.logo {
  img {
    user-select: none;
    cursor: pointer;
    max-width: 100%;
    height: auto;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: right;
  list-style: none;
  margin: 0;
  padding: 0;

  // @media (max-width: 768px) {
  //   display: none;
  // }
  .menu-item {
    margin-right: 58px;
    font-size: 1.6rem;
    color: var(--color--fonts);
    a {
      font-weight: 400;
      position: relative;
      &::after {
        content: "";
        transition-duration: 0.2s;
        height: 1px;
        width: 0%;
        position: absolute;
        left: 0px;
        bottom: -2px;
        // background: var(--color--primary);
        border-bottom: 1px dashed var(--color--primary);;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
    @media (max-width: 1600px) {
      margin-right: 48px;
    }
    @media (max-width: 1400px) {
      margin-right: 32px;
    }
  }
}

.hamburger {
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }

  .hamburger-line {
    display: block;
    width: 30px;
    height: 3px;
    margin-bottom: 6px;
    background-color: white;
    transition: background-color 0.3s ease;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mobile-menu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    display: block;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
  }
}

.mobile-menu-open {
  .mobile-menu {
    display: block;
  }
}

.theme-light {
  .navbar {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
