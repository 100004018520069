.swiperWrapper {

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    background: white;
    padding-top: 24px;
    bottom: 0px;
  }

  .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0);
    font-size: 1.6rem;
    font-weight: bold;
    color: #ccc;
    opacity: 1;
    padding-left: 12px;
    padding-right: 24px;
    padding-bottom: 32px;

    &:after {
      content: '/';
      margin-left: 12px;
      color: #ccc !important;
    }

    &:nth-last-child(1) {
      &:after {
        display: none;
      }
    }
  }

  .swiper-pagination-bullet-active {
    background: rgba(0, 0, 0, 0);
    font-size: 1.6rem;
    padding-left: 12px;
    padding-right: 24px;
    padding-bottom: 32px;
    color: rgb(138, 0, 0);
  }

  .sliderBg {
    height: calc(100vh - 160px);

    @media all and (max-width: 700px) {
      height: 400px;
    }

    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .sliderContent {
      text-align: center;

      .title {
        font-size: 4.8rem;
        color: #f1f1f1;
        font-weight: 700;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .description {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 2.4rem;
        color: #f1f1f1;
        font-weight: 700;
        @media (max-width: 1200px) {
          font-size: 2rem;
        }
        @media (max-width: 900px) {
          font-size: 1.8rem;
        }
      }
    }
  }


  &.swiperWrapperSmall {
    .sliderBg {
      height: 600px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}