.home-page {
  .ifyouare-section {
    h2 {
      font-size: 6.4rem;
      font-weight: 400;
      line-height: 120%;
      @media (max-width: 1200px) {
        font-size: 4.6rem;
        line-height: 130%;
        padding-right: 32px;
        br {
          display: none;
        }
      }
      @media (max-width: 900px) {
        font-size: 3.6rem;
        padding-right: 32px;
      }
    }
  }
}
