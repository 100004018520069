:root {
  --color--primary: rgba(143, 255, 92, 1);
  --color--primary-0a: rgba(143, 255, 92, 0);
  --color--primary-5a: rgba(143, 255, 92, 0.05);
  --color--primary-10a: rgba(143, 255, 92, 0.1);
  --color--primary-20a: rgba(143, 255, 92, 0.2);
  --color--primary-30a: rgba(143, 255, 92, 0.3);
  --color--primary-50a: rgba(143, 255, 92, 0.5);

  --color--primary-dark: hsl(101, 100%, 62%);
  --color--primary-light: hsl(101, 100%, 74%);

  --color--background: rgb(255, 255, 255, 1);
  --color--background-00a: rgba(255, 255, 255, 0);
  --color--bg-box: rgba(255, 255, 255, 1);
  --color--bg-box-dark: rgba(10, 10, 10, 1);

  --color--fonts: rgba(0, 0, 0, 1);
  --color--fonts-on-primary: rgb(0, 0, 0, 1);
  --color--fonts-on-primary-a0: rgb(0, 0, 0, 0);

  --color--border-light: rgba(190, 190, 190, 1);
  --color--border-lighter: rgba(220, 220, 220, 1);

  --color--boxshadow: rgba(0, 0, 0, 0.15);
  --color--boxshadow-light: rgba(0, 0, 0, 0.05);

  --color--inputs-autofill-bg: rgba(238, 238, 238, 0.6);
  --color--error: rgb(200, 10, 10);
  --color--overlay: rgba(0, 0, 0, 0.5);
}

.theme-dark:root {
  --color--primary: rgba(143, 255, 92, 1);
  --color--primary-0a: rgba(143, 255, 92, 0);
  --color--primary-5a: rgba(143, 255, 92, 0.05);
  --color--primary-10a: rgba(143, 255, 92, 0.1);
  --color--primary-20a: rgba(143, 255, 92, 0.2);
  --color--primary-30a: rgba(143, 255, 92, 0.3);
  --color--primary-50a: rgba(143, 255, 92, 0.5);

  --color--primary-dark: hsl(101, 100%, 62%);
  --color--primary-light: hsl(101, 100%, 74%);

  --color--background: rgb(23, 21, 20, 1);
  --color--background-00a: rgba(23, 21, 20, 0);
  --color--bg-box: rgba(23, 21, 20, 1);
  --color--bg-box-dark: rgba(30, 30, 30, 1);

  --color--fonts: rgba(255, 255, 255, 1);
  --color--fonts-on-primary: rgb(0, 0, 0, 1);
  --color--fonts-on-primary-a0: rgb(0, 0, 0, 0);

  --color--border-light: rgba(40, 40, 40, 1);
  --color--border-lighter: rgba(55, 55, 55, 1);

  --color--boxshadow: rgba(0, 0, 0, 0.15);
  --color--boxshadow-light: rgba(0, 0, 0, 0.05);

  --color--inputs-autofill-bg: rgba(38, 38, 38, 0.6);
  --color--error: rgb(200, 10, 10);
  --color--overlay: rgba(0, 0, 0, 0.5);
}
