.standardTile {
  background: var(--color--primary);
  border-radius: 10px 10px 40px 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    border-radius: 8px 8px 24px 8px;
  }
  &.isWhite {
    background: var(--color--fonts);
    a{
      color: var(--color--fonts-on-primary);
    }
  }
  &.disableLargeRadius {
    border-radius: 10px 10px 10px 10px;
    @media (max-width: 1200px) {
      border-radius: 8px 8px 8px 8px;
    }
  }
  padding: 40px;
  @media (max-width: 1200px) {
    padding: 24px;
  }
  &.miniPadding {
    padding: 10px;
  }
  color: var(--color--fonts-on-primary);
  border: 1px dashed var(--color--primary);

  h3 {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  p {
    font-size: 1.6rem;
    line-height: 1.4;
  }
  &.hoverable {
    &:hover {
      background: var(--color--primary-a0);
      color: var(--color--primary);
    }
  }
  &.always-hover {
    background: var(--color--primary-a0) !important;
    color: var(--color--primary) !important;
  }
}

.standardTile {
  &[data-aos="reveal-tile"] {
    background: var(--color--primary-a0);
    color: var(--color--fonts-on-primary-a0);

    &.aos-animate {
      background: var(--color--primary);
      color: var(--color--fonts-on-primary);
    }
    &.hoverable {
      &.aos-animate {
        &:hover {
          background: var(--color--primary-a0);
          color: var(--color--primary);
          transition-duration: 0s;
          transition-delay: 0s !important;
        }
      }
    }
  }
}

.theme-light {
  .standardTile {
    &.isWhite {
      border-color: #e2e2e2;
      border-style: solid;
      background: white !important;
    }

    &.hoverable {
      &:hover {
        color: var(--color--fonts) !important;
        // background: white !important;
        border-color: var(--color--fonts);
      }
    }
    &.always-hover {
      color: var(--color--fonts) !important;
      // background: white !important;
      border-color: var(--color--fonts) !important;
    }
    &.isWhite {
      // background: var(--color--background);
    }
  }
}
