.home-page {
  .approach-section {
    h2 {
      color: var(--color--primary);
      leading-trim: both;
      text-edge: cap;
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 120%; /* 28.8px */
      @media (max-width: 1200px) {
        font-size: 2rem;
      }
      @media (max-width: 900px) {
        font-size: 1.8rem;
      }
    }
    .d-flex {
      display: flex !important;
    }
    h3 {
      line-height: 1.2;
      img {
        transform: translateY(5px);
      }
    }
  }
}
