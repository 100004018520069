.theme-switch {
  background: var(--color--primary);
  height: 24px;
  width: 24px;
  aspect-ratio: 1/1;
  border-radius: 24px;
  cursor: pointer;
  user-select: none;
  transition-duration: 0s;
  .image-box {
    position: relative;
    height: 100%;
    width: 100%;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    &#light-icon {
      transform: translateX(-50%) translateY(-50%) scale(1.2);
      &.hidden {
        transform: translateX(-50%) translateY(-50%) scale(0) rotate(180deg);
      }
    }
    &.hidden {
      transform: translateX(-50%) translateY(-50%) scale(0) rotate(-180deg);
    }
  }

  &:hover {
    background: white;
  }
}

.theme-light {
  .theme-switch {
    &:hover {
      background: black;
      transition-duration: 0s;
      .image-box {
        filter: invert(1);
      }
    }
  }
}
