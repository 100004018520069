.portfolio-page {
  .gallery-section {
    img {
      border-radius: 12px;
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: center;
      aspect-ratio: 1400/640;
    }
  }
}

.theme-light {
  .portfolio-page {
    .gallery-section {
      img {
        box-shadow: 0px 0px 1px 1px #e2e2e250;
      }
    }
  }
}
