.portfolio-page {
  .hero-section {
    .back-icon {
      user-select: none;
    }
    h1 {
      font-size: 12.8rem;
      font-weight: 500;
      margin-left: -4px;
      @media (max-width: 1200px) {
        font-size: 9rem;
      }
      @media (max-width: 900px) {
        font-size: 6rem;
      }
    }
    .pretitle {
      font-size: 2.4rem;
      font-weight: 500;
      @media (max-width: 1200px) {
        font-size: 2rem;
      }
      @media (max-width: 900px) {
        font-size: 1.8rem;
      }
    }
  }
}

.theme-light {
  .back-icon {
    filter: invert(1);
  }
}
