.home-page {
  .portfolio-section {
    h2 {
      font-size: 6.4rem;
      font-weight: 400;
      line-height: 120%;
      @media (max-width: 1200px) {
        font-size: 4.6rem;
      }
      @media (max-width: 900px) {
        font-size: 3.6rem;
      }
    }

    @media (max-width: 900px) {
      h2.sectiontitle {
        color: var(--color--primary);
        leading-trim: both;
        text-edge: cap;
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 120%; /* 28.8px */
        font-size: 1.8rem;
        transform: translateY(5px);
        display: block !important;
      }
    }

    .badge {
      background: var(--color--primary);
      color: var(--color--fonts-on-primary);
      padding: 0px 1rem;
      border-radius: 20px;
      display: inline-flex;
      line-height: 100% !important;
    }
  }

  .portfolio-tile {
    background-color: var(--color--fonts);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    background-position: center center;
    aspect-ratio: 2.4/1;
    border-radius: 10px 10px 40px 10px;
    transition-duration: 0.1s;

    position: sticky;
    top: 120px;
    margin-bottom: 260px;

    .disabled {
      pointer-events: none;
      filter: grayscale(100%) brightness(90%);
      overflow: hidden;
      .portfolio-tile-image {
        pointer-events: all;
        cursor: not-allowed;
      }
    }
    @media all and (max-width: 2580px) {
      margin-bottom: 180px;
    }
    @media (max-width: 1200px) {
      border-radius: 8px 8px 24px 8px;
      aspect-ratio: 1.9/1;
    }
    .portfolio-tile-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      transition-duration: 0.1s;
      width: 100%;
      border-radius: 10px 10px 40px 10px;
      height: 100%;

      @media (max-width: 1200px) {
        border-radius: 8px 8px 24px 8px;
      }
    }
  }

  .portfolio-tile-helper {
    width: 100%;
    height: 0px;
  }
  .portfolio-menu-wrapper {
    transition-duration: 0.1s;
    position: sticky;
    top: 300px;
    padding-bottom: 150px;
  }

  .portfolio-menu {
    flex-direction: column;
    display: flex;
    .portfolio-menu-item {
      cursor: pointer;
      pointer-events: all;
      font-size: 1.6rem;
      font-weight: 500;
      position: relative;
      margin-right: auto;
      display: inline-block;

      &::after {
        content: "";
        transition-duration: 0.2s;
        height: 1px;
        width: 00%;
        position: absolute;
        left: 0px;
        bottom: -2px;
        // background: var(--color--primary);
        border-bottom: 1px dashed var(--color--primary);
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }

      &.active {
        color: var(--color--primary);
      }
    }
  }
}
