.footer {
  padding: 0px 32px 64px 32px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 600px) {
    padding: 0px 16px 48px 16px;
    // border-radius: 8px 8px 24px 8px;
  }
  .primary-tile {
    background: var(--color--primary);
    color: var(--color--fonts-on-primary);
    border-radius: 20px 20px 80px 20px;
    @media (max-width: 1200px) {
      border-radius: 12px 12px 36px 12px;
      padding: 0px 8px;
    }
    @media (max-width: 900px) {
      border-radius: 8px 8px 24px 8px;
    }
    .Typewriter__wrapper {
      color: var(--color--fonts-on-primary);
    }
    h2 {
      font-size: 6.4rem;
      font-weight: 400;
      @media (max-width: 1200px) {
        font-size: 4.6rem;
      }
      @media (max-width: 900px) {
        font-size: 3.6rem;
      }
    }
    .first-col{
      @media (max-width: 600px) {
        width: 100% !important;
      }
    }
    .links {
      @media (max-width: 600px) {
        width: 100% !important;
      }
      a {
        color: var(--color--fonts-on-primary);
        display: inline-flex;
        font-size: 1.6rem;
        margin-bottom: 16px;
        img {
          margin-left: 2px;
          margin-bottom: -2px;
        }
      }
    }
    .button {
      border: 1px solid black;
      font-size: 1.6rem;
      &:hover {
        transition-duration: 0s !important;
        border-color: white !important;
        background: white !important;
        color: black !important;
      }
      // img {
      //   // margin-right: 4px;
      // }
    }
  }

  &.stylevariant-light {
    .primary-tile {
      div {
        filter: invert(1);
      }
      .Typewriter__cursor,
      .Typewriter__wrapper {
        filter: invert(1);
      }
    }
  }

  .contact-me-btn {
    display: inline-flex;
  }
}
