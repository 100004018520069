.home-page {
  .process-section {
    h2 {
      color: var(--color--primary);
      leading-trim: both;
      text-edge: cap;
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 120%; /* 28.8px */
      @media (max-width: 1200px) {
        font-size: 2rem;
      }
      @media (max-width: 900px) {
        font-size: 1.8rem;
      }
    }
    h3 {
      font-size: 6.4rem;
      font-weight: 400;
      line-height: 120%;
      @media (max-width: 1200px) {
        font-size: 4.6rem;
      }
      @media (max-width: 900px) {
        font-size: 3.6rem;
      }
    }
    .d-flex {
      display: flex !important;
    }
    .standardTile{
      width: 100%;
    }
  }
}

.theme-light {
  .home-page {
    .process-section {
      .standardTile {
        &.always-hover {
          border-color: black;
          color: black;
        }
      }
    }
  }
}
