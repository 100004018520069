.home-page {
  .hello-section {
    h2 {
      font-size: 6.4rem;
      font-weight: 400;
      line-height: 120%;

      @media (max-width: 1200px) {
        font-size: 4.6rem;
      }
      @media (max-width: 900px) {
        font-size: 3.6rem;
      }
    }

    .badge {
      background: var(--color--primary);
      color: var(--color--fonts-on-primary);
      padding: 0px 1rem;
      border-radius: 20px;
      display: inline-flex;
      line-height: 100% !important;
    }
  }
}
