.portfolio-page {
  .description-section {
    .text-block {
      p {
        font-weight: 400;
      }
    }
    .buttons-wrapper-col {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 600px) {
        justify-content: flex-start;
      }
      .buttons-wrapper {
        padding-top: 64px;
        padding-right: 64px;
        display: flex;
        justify-content: flex-end;
        height: auto;
        margin-top: auto;
      }
      .button {
        border-color: black;
        font-size: 1.6rem;
        &:hover {
          transition-duration: 0s !important;
          border-color: white;
          background: white;
          color: black;
        }
      }
    }
  }
}

.theme-dark {
  .portfolio-page {
    .description-section {
      .buttons-wrapper-col {
        .button {
          filter: invert(1);
          &:hover {
            filter: invert(0);
            transition-duration: 0s !important;
            border-color: white;
            background: white;
            color: black;
          }
        }
      }
    }
  }
}
