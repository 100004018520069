$cloud-fluctuation: 200px;
$cloud-size: 600px;

.cloud-container-wrapper {
  height: 0px;
  opacity: 0.80;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  overflow-x: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  @media (max-width: 900px) {
    display: none;
  }
  // z-index: 0;
}
.cloud-container {
  width: 100%;
  max-width: 100%;
  // position: absolute;
  pointer-events: none;
  padding: calc($cloud-fluctuation * 4) 0px;
  overflow-x: hidden;

  .cloud {
    width: $cloud-size;
    height: $cloud-size;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: blur(100px);

    animation: cloudAnimation 40s linear infinite;
    
    @keyframes cloudAnimation {
      0% {
        left: 10%;
      }
      50% {
        left: 90%;
      }

      100% {
        left: 10%;
      }
    }

    svg {
      width: 100%;
      height: auto;
      // filter: blur(100px);
      opacity: 1;
      left: 10%;
      transform: translateX(-50%) rotate(0deg);

      animation: cloudAnimationSvg 30s linear infinite;
      @keyframes cloudAnimationSvg {
        0% {
          width: 100%;
          opacity: 1;
          margin-bottom: $cloud-fluctuation;
          margin-top: 0px;
          transform: translateX(-50%) rotate(0deg);
          border-radius: 0px;
        }
        15% {
          width: 140%;
          opacity: 0.95;
          margin-bottom: 0px;
          margin-top: $cloud-fluctuation;
        }
        50% {
          width: 110%;
          opacity: 0.95;
          margin-bottom: $cloud-fluctuation;
          margin-top: 0px;
          border-radius: 1000px;
        }
        85% {
          width: 140%;
          opacity: 0.9;
          margin-bottom: 0px;
          margin-top: $cloud-fluctuation;
        }
        100% {
          width: 100%;
          opacity: 1;
          margin-bottom: $cloud-fluctuation;
          margin-top: 0px;
          transform: translateX(-50%) rotate(360deg);
          border-radius: 0px;
        }
      }
    }
  }
}
