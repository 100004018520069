@use "../styles/_style.scss";

html{
  background: var(--color--background);
  z-index: -1;
}
body {
  min-height: calc(100vh + 1px);
  background: var(--color--background);
  transition-duration: 0.75s;
}

.routes-wrapper {
  min-height: 50vh;
  padding-top: 80px;
  & > div {
    position: relative;
    // z-index: -1;
    * {
      z-index: 1;
    }
  }
}

_::-webkit-full-page-media,
_:future,
:root .safari_only {
  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
    transform: none !important;
  }
}

_::-webkit-full-page-media,
_:future,
:root .safari_only {
  [data-aos^="fade-in"][data-aos^="fade-in"] {
    opacity: 1 !important;
    transform: none !important;
  }
}

* {
  @media only screen and (max-width: 900px) {
    [data-aos^="fade"][data-aos^="fade"] {
      opacity: 1 !important;
      transform: none !important;
    }
    [data-aos^="fade-in"][data-aos^="fade-in"] {
      opacity: 1 !important;
      transform: none !important;
    }
  }
}

#app {
  // display: initial;
  // @media only screen and (max-width: 900px) {
  //   display: none;
  // }
}

// .app-in-progress {
//   display: none;

//   @media only screen and (max-width: 900px) {
//     position: relative;
//     display: block;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     .title {
//       font-size: 10rem;
//       z-index: 2;
//     }

//     .cloud-wrapper {
//       position: relative;
//       position: fixed;
//       z-index: 0;
//       height: 100%;
//       width: 100%;
//     }
//   }
// }
