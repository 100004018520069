html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Outfit", sans-serif;
  letter-spacing: 0.05em;


  @media all and (max-width: 700px) {
    font-size: 9px;
    letter-spacing: 0.03em;
  }
}

* {
  font-family: "Outfit", sans-serif;
}

a {
  text-decoration: none;
  color: var(--color--fonts);
}
body {
  background: var(--color--background);
  color: var(--color--fonts);
}

*:focus,
textarea:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // -webkit-box-shadow: 0 0 0px 50px var(--color--inputs-autofill-bg) inset !important;
  box-shadow: 0 0 0px 50px var(--color--inputs-autofill-bg) inset !important;
  -webkit-text-fill-color: var(--color--fonts) !important;
  font-size: 1.4rem !important;
  background-color: var(--color--background) !important;
}

::selection {
  background: var(--color--primary) !important;
  color: var(--color--fonts-on-primary) !important;
}

::placeholder {
  opacity: 1;
  color: var(--color--border-light) !important;
  -webkit-text-fill-color: var(--color--border-light) !important;
}

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
}

.ReactCollapse--collapse {
  transition: height 0.15s;
}

@page {
  size: auto;
  margin: 0mm;
}

svg {
  path[fill="black"] {
    fill: var(--color--fonts) !important;
  }
}

.show-on-mobile {
  display: none !important;

  @media all and (max-width: 900px) {
    display: initial !important;
  }
}

.hide-on-mobile {
  @media all and (max-width: 900px) {
    display: none !important;
  }
}
