.home-page {
  .questions-section {
    h2 {
      color: var(--color--primary);
      leading-trim: both;
      text-edge: cap;
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 120%; /* 28.8px */
      @media (max-width: 1200px) {
        font-size: 2rem;
      }
      @media (max-width: 900px) {
        font-size: 1.8rem;
      }
    }
    .d-flex {
      display: flex !important;
    }

    .standardTile {
      cursor: move;
      user-select: none;
    }

    .swiper {
      padding-bottom: 24px;
    }
    .swiper-scrollbar {
      margin-top: 48px !important;
      background: var(--color--primary-10a);
      .swiper-scrollbar-drag {
        background: var(--color--primary);
      }
    }
  }
}
